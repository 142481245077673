table {
  border-color: #2b2b2b;
}

button {
  border: 1px solid transparent;
}

.bold {
  font-weight: 700;
}

.uk-mr-mini {
  margin-right: 10px;
}

.uk-button {
  color: white;
}

.uk-table {
  margin-bottom: 0;
}

.spinner-content > div {
  left: 50px;
  margin-bottom: 0.25em;
}

.spinner-content > div > div {
  background-color: white;
}

.completable {
  background-color:#fdfdaf;
  color: #2b2b2b;
}

.completable button, .incompletable button {
  background-color: #4CAF50;
}

.incompletable {
  background-color: #ff6962;
  color: #2b2b2b;
}

.completed {
  background-color: #77dd77;
  color: #2b2b2b;
}

.completed button {
  background-color: #f44336;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover, .link-button:focus {
  text-decoration: none;
}

.prevent-scroll {
  overflow-y: hidden;
}

#nav-sticky {
  height: 80px;
}

#error {
  margin: 0;
  padding: 0;
}